
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import * as Yup from "yup";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "underwriter-policy-document-upload",
  components: { ErrorMessage, Field, Form },
  props: {
    redirect: String,
    policyRoute: String,
    policiesRoute: String,
    clientsRoute: String,
    role: String,
  },
  data() {
    return {
      displayTable: false,
      clients: [],
      policies: [],
      clientPublicId: "",
      policyNumber: "",
      policyDocument: "",
      policySummary: {
        client: "",
        type: "",
        reference: "",
        year: "",
      },
      hasPolicyDocument: false,
      submitRoute: "",
    };
  },
  setup(props) {
    const submitButton1 = ref<HTMLElement | null>(null);
    const router = useRouter();

    const documentUploadValidator = Yup.object().shape({
      clientPublicId: Yup.string()
        .required()
        .label("Client"),
      policy: Yup.string()
        .required()
        .label("IBN reference"),
      policyNumber: Yup.string()
        .required()
        .label("Policy number"),
      // policyDocument: Yup.mixed()
      //   .required()
      //   .label("Policy document"),
    });

    const submitForm = (values, { resetForm }, props) => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Submit data
          if (JwtService.getToken()) {
            const formData = new FormData();
            formData.append("policyNumber", values["policyNumber"]);
            formData.append(
              "policyDocument",
              values["policyDocument"] as string | Blob
            );

            // return new Response(formData).text().then(console.log);

            ApiService.setHeader();
            ApiService.post(
              `${values["route"]}/${values["policy"]}/document`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
              .then((response) => {
                variables.toastAlert(response.data.data.message, "success");
                resetForm();
                router.push({ name: props.redirect });
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Upload Policy Document", [
        "Policies",
        "Policy Documentation",
      ]);
      MenuComponent.reinitialization();
    });

    return { submitButton1, documentUploadValidator, submitForm };
  },
  created() {
    this.submitRoute =
      this.role == variables.UNDERWRITER_USER_ROLE ? "underwriter" : "policy";

    // Get underwriters clients
    this.getClients();

    //Set page title
    document.title = "Policy Document Upload | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    // Get list of clients based on user roles
    getClients() {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${this.clientsRoute}`)
          .then(({ data }) => {
            // Assign data to variables
            this.clients = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    getClientPolicies(event) {
      this.displayTable = false;

      const route =
        this.role == variables.UNDERWRITER_USER_ROLE
          ? `${this.policiesRoute}/${event.target.value}/policies`
          : `${this.policiesRoute}/${event.target.value}/policies/?withPolicyNumber=false`;

      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(route)
          .then(({ data }) => {
            // Assign data to variables
            this.policies = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    getPolicy(event) {
      this.displayTable = false;
      const underwriteRole = variables.UNDERWRITER_USER_ROLE;

      const route =
        this.role == underwriteRole
          ? `${this.policyRoute}/${event.target.value}/details`
          : `${this.policyRoute}/${event.target.value}`;

      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(route)
          .then(({ data }) => {
            this.displayTable = true;

            // Assign data to variables
            if (this.role == underwriteRole) {
              this.policySummary = data.data;
              this.policyNumber =
                data.data.number != "T.B.A." ? data.data.number : "";
              this.hasPolicyDocument = data.data.hasPolicyDocument;
            } else {
              this.policySummary = data.data.policySummary;
              this.hasPolicyDocument =
                data.data.policySummary.hasPolicyDocument;
              this.policyNumber =
                data.data.policySummary.number != "T.B.A."
                  ? data.data.policySummary.number
                  : "";
            }
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    documentSelected(event) {
      this.policyDocument = event.target.files[0];
    },
  },
});
